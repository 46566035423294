html, body {
    height: 100vh;
}

body {
    color: #676767;
    font-size: 1rem;
    line-height: 100%;
    font-family: 'Camphor', sans-serif;
}

body, .wrapper {
    min-height: 100%;
    height: 100%;
}