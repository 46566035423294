.stripes-wrapper {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #FDC830;
    background: -webkit-linear-gradient(150deg, #FDC830 30%, #F37335 77%);
    background: linear-gradient(150deg, #FDC830 30%, #F37335 77%);
    z-index: -1;
}

.stripes-wrapper .stripes {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: calc(100% + 1200px);
    top: 0;
    left: 0;
    overflow: hidden;
    transform: skewY(-30deg);
    transform-origin: 0;
}

.stripes-wrapper .stripes span {
    position: absolute;
    height: 190px;
}

.stripes-wrapper .stripes :nth-child(1) {
    width: 33.33333%;
    left: -16.66666%;
    background: #fdc830;
    top: 300px;
    height: 190px;
}

.stripes-wrapper .stripes :nth-child(2) {
    width: 33.33333%;
    top: 300px;
    left: 16.66666%;
    right: auto;
    background: #fdc131;
    bottom: auto;
}

.stripes-wrapper .stripes :nth-child(3) {
    width: 33.33333%;
    left: 80.99999%;
    bottom: 800px;
    background: #ffc14d;
    opacity: 0.2;
    right: auto;
}

.stripes-wrapper .stripes :nth-child(4) {
    width: 33.33333%;
    top: 380px;
    right: -16.66666%;
    background: #e29033;
    opacity: 0.2;
}

.stripes-wrapper .stripes :nth-child(5) {
    width: 33.33333%;
    bottom: 40%;
    background: #f68b33;
}

.login-component .box {
    max-width: 400px;
    width: 100%;
}

.login-component .box .signin-header {
    position: relative;
    height: 80px;
    width: 100%;
}

.login-component .box .signin-header img {
    height: 80px;
    width: auto;
}

.login-component .box .signin-body {
    box-shadow: 0 5px 24px 0 rgba(50, 49, 58, .25);
    border-radius: 10px;
    padding: 30px;
    background-color: #fff;
}

.login-component .box .signin-body h2 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 1.3rem;
    text-align: center;
    color: #333;
}

.login-component .box .has-feedback {
    position: relative;
}

.login-component .box .form-control:focus {
    box-shadow: none;
    border-color: #f68934;
}

.login-component .box .has-feedback .form-control {
    padding-right: 12px;
    padding-left: 40px;
    font-size: 1.1rem;
    height: 40px;
    border-radius: 8px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.login-component .box .form-control-feedback {
    position: absolute;
    top: 0;
    z-index: 2;
    display: block;
    width: 40px;
    left: 0;
    right: auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    pointer-events: none;
}

.login-component .box .form-control-feedback svg {
    color: #f68934;
}

.login-component .box .signin-link, .login-component .box .signin-link:hover, .login-component .box .signin-link:active, .login-component .box .signin-link:focus {
    text-decoration: none !important;
    color: #f68634;
}

.login-component .box .signin-btn, .login-component .box .signin-btn:focus, .login-component .box .signin-btn:hover {
    background: linear-gradient(150deg, #f68b34 15%, #f89c33 70%, #f68634 94%);
    color: #fff;
    font-size: 1.2rem;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgb(232 115 5 / 0.7);
    border-radius: 25px;
}

.login-component .box .signin-footer h3 {
    font-size: 1.2rem;
    color: #FFF;
    font-weight: 300;
}

.login-component .box .signin-footer h3 span {
    font-weight: normal;
    font-size: 18px;
    position: relative;
    display: block;
}

.login-component .box .signin-footer h3 span::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.2);
    width: 100px;
    height: 1px;
    position: absolute;
    right: 25px;
    top: 10px;
}

.login-component .box .signin-footer h3 span::after {
    content: "";
    background-color: rgba(255, 255, 255, 0.2);
    width: 100px;
    height: 1px;
    position: absolute;
    left: 25px;
    top: 10px;
}

.login-component .box .signin-footer a {
    padding: 5px;
    border-radius: 10px;
    display: inline-block;
    margin: 0 5px;
    background-color: white;
    color: #f37335;
}

.login-component .box .signin-footer a svg {
    width: 18px;
    height: 18px;
}

.login-component .box .contact span {
    color: #FFF;
    font-size: 1.3rem;
}

.login-component .box .contact svg {
    color: #FFF;
}

.bg-dark {
    background-color: #282733 !important;
}

.register-component .box {
    width: 80%;
}

.register-component .box .signin-header {
    position: relative;
    height: 80px;
    width: 100%;
}

.register-component .box .signin-header img {
    height: 80px;
    width: auto;
}

.register-component .box .signin-body {
    box-shadow: 0 5px 24px 0 rgba(50, 49, 58, .25);
    border-radius: 10px;
    padding: 30px;
    background-color: #fff;
}

.register-component .box .signin-body h2 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 1.3rem;
    text-align: center;
    color: #333;
}

.register-component .box .has-feedback {
    position: relative;
}

.register-component .box .form-control:focus {
    box-shadow: none;
    border-color: #f68934;
}

.register-component .box .has-feedback .form-control {
    padding-right: 12px;
    padding-left: 40px;
    font-size: 1.1rem;
    height: 40px;
    border-radius: 8px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.register-component .box .form-control-feedback {
    position: absolute;
    top: 0;
    z-index: 2;
    display: block;
    width: 40px;
    left: 0;
    right: auto;
    height: 40px;
    line-height: 40px;
    text-align: center;
    pointer-events: none;
}

.register-component .box .form-control-feedback svg {
    color: #f68934;
}

.register-component .box .signin-link, .register-component .box .signin-link:hover, .register-component .box .signin-link:active, .register-component .box .signin-link:focus {
    text-decoration: none !important;
    color: #f68634;
}

.register-component .box .signin-btn, .register-component .box .signin-btn:focus, .register-component .box .signin-btn:hover {
    background: linear-gradient(150deg, #f68b34 15%, #f89c33 70%, #f68634 94%);
    color: #fff;
    font-size: 1.2rem;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgb(232 115 5 / 0.7);
    border-radius: 25px;
}

.register-component .box .signin-footer h3 {
    font-size: 1.2rem;
    color: #FFF;
    font-weight: 300;
}

.register-component .box .signin-footer h3 span {
    font-weight: normal;
    font-size: 18px;
    position: relative;
    display: block;
}

.register-component .box .signin-footer h3 span::before {
    content: "";
    background-color: rgba(255, 255, 255, 0.2);
    width: 100px;
    height: 1px;
    position: absolute;
    right: 25px;
    top: 10px;
}

.register-component .box .signin-footer h3 span::after {
    content: "";
    background-color: rgba(255, 255, 255, 0.2);
    width: 100px;
    height: 1px;
    position: absolute;
    left: 25px;
    top: 10px;
}

.register-component .box .signin-footer a {
    padding: 5px;
    border-radius: 10px;
    display: inline-block;
    margin: 0 5px;
    background-color: white;
    color: #f37335;
}

.reset-component .box {
    width: 45%;
}

.reset-component .box .signin-header {
    position: relative;
    height: 80px;
    width: 100%;
}

.reset-component .box .signin-header img {
    height: 80px;
    width: auto;
}

.reset-component .box .signin-body {
    box-shadow: 0 5px 24px 0 rgba(50, 49, 58, .25);
    border-radius: 10px;
    padding: 30px;
    background-color: #fff;
}

.reset-component .box .signin-body h2 {
    margin: 0;
    margin-bottom: 20px;
    font-size: 1.3rem;
    text-align: center;
    color: #333;
}

.main-wrapper {
    width: 100%;
    max-width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 60px;
}

.box-shadow {
    box-shadow: 0px 0px 30px 0px rgba(52, 50, 71, 0.075);
}

.border-radius-3 {
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    border-radius: 0.3rem;
}

.main {
    position: relative;
    padding: 3%;
    z-index: auto;
    margin: auto;
    max-width: 100%;
    width: 100%;
}

.filter {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1rem;
    margin: 0 0 1rem 0;
    z-index: 15;
    color: #6c7293;
    background-color: #ffffff;
}

.filter .filter-welcome {
    font-size: 1.3rem;
}

.card {
    border: none;
    margin-bottom: 1.4rem;
    color: #48465b;
}

.card-up {
    height: 200px;
    overflow: hidden;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    -webkit-border-top-left-radius: 0.3rem;
    -moz-border-top-left-radius: 0.3rem;
    background-position: left center;
    background-size: cover;
    background-repeat: no-repeat;
}

.card .avatar {
    display: block;
    width: 130px;
    height: 130px;
    margin-top: -60px;
    overflow: hidden;
    background-color: #FFF;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    border: 5px solid #e6e6e6;
}

.card-title, .card-title:hover, .card-title:focus {
    font-size: 1.2rem;
    font-weight: 600;
    color: #48465b;
    text-decoration: none;
}

.card .card-info__item {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    margin-left: 5px;
    padding: 5px;
    background: #e8e8e8;
    border-radius: 50%;
    cursor: pointer;
}

.card-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
}

.card-icon svg {
    width: 24px;
    height: 24px;
}

.card .icon-marker-map svg {
    height: 15px;
    width: 15px;
}

.card .text-map {
    font-weight: 600;
}

.search-wrapper {
    border-left: 1.5px solid #8f9192;
    padding-left: 20px;
    margin-left: 20px;
}

.filter-input, .filter-input:hover, .filter-input:focus, .filter-input:active {
    color: #48465b;
    background-color: #f0f3f4;
    border: 1px solid #eee!important;
    box-shadow: none!important;
    vertical-align: middle;
    display: inline-block;
    padding: 5px 15px;
    font-weight: 600;
    margin: 0;
    height: 40px;
}

.btn-search {
    background: linear-gradient(150deg, #f68b34 15%, #f89c33 70%, #f68634 94%);
    color: #fff;
}

.btn-search:hover {
    background: linear-gradient(150deg, #db7826 15%, #e48921 70%, #dd772e 94%);
    color: #fff;
}

@media (min-width: 1600px) {
    .row-cols-xs-4>* {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (max-width: 763px) {
    .search-wrapper {
        width: 100%;
        border-left: none;
        padding-left: 0;
        margin-left: 0;
    }
    .filter-input {
        width: 46.4% !important;
    }
}

/** VIEW **/

.header {
    position: relative;
    height: initial;
}

.header-cover {
    position: relative;
    height: 300px;
    color: #FFF;
    background-size: cover;
    background-position: center bottom;
}

.header-cover:after, .header-cover:before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    content: "";
    width: 100%;
    height: 100%;
}

.header-cover:before {
    background-color: rgba(0, 0, 0, .6);
}

.header-cover:after {
    opacity: .6;
    /*background-image: url('../images/dotted-bg.png');*/
}

.header-container {
    position: relative;
    z-index: 5;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    width: 100%;
    margin: 0 auto;
    color: #FFF;
    height: 100%;
}

.header-avatar {
    position: relative;
    min-width: 200px;
    min-height: 200px;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: 80%;
    background-color: #FFF;
    background-position: center;
    border: 8px solid #f4f4f4;
}

.title-container h3 {
    font-weight: 700;
}

.title-container .badge {
    font-size: .9rem;
    font-weight: 300;
}

.info-item {
    position: relative;
    display: inline-block;
    padding: 10px;
}

.info-item .icon {
    height: 30px;
    width: 30px;
}

.tabs-enterprise .nav-link {
    color: #48465b;
    font-size: 1rem;
    font-weight: 600;
    padding: 25px;
    cursor: pointer;
    border-radius: 0;
}

.tabs-enterprise {
    width: 100%;
    max-width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    margin-right: auto;
    margin-left: auto;
}

.tab-wrapper {
    width: 100%;
    max-width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    margin-right: auto;
    margin-left: auto;
}

.tabs-enterprise .nav-link.active {
    background: transparent !important;
    color: #f68634;
    border-bottom: 4px solid #f68634;
}

.tab-wrapper .tab-content {
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    border-radius: 0.3rem;
}

@media (max-width: 800px) {
    .register-component .box, .reset-component .box {
        width: 95%;
    }
    .main-wrapper {
        margin-top: 80px;
    }
    .header-cover {
        height: auto;
        padding-top: 5%;
        padding-bottom: 5%;
    }
    .header-avatar {
        margin-right: auto;
        margin-left: auto;
    }
    .filter-toolbar {
        width: 100%;
    }
}

.view-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    background: #4a69bd;
    border-radius: 10px;
    color: #FFF;
    cursor: pointer;
    border: 1px solid #3957aa;
}

.view-button svg {
    fill: #FFF;
    height: 64px;
    width: 64px;
}

.view-button-label {
    text-align: center;
    font-size: 1rem;
    text-transform: uppercase;
}

.register-component .g-recaptcha>div, .reset-component .g-recaptcha>div {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}